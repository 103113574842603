var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "flex items-center justify-center" }, [
    _c("div", { staticClass: "w-12 font-semibold text-center pb-2" }, [
      _vm._v(_vm._s(_vm.sliderMin)),
    ]),
    _c("div", { staticClass: "relative w-64" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.sliderMin,
            expression: "sliderMin",
          },
        ],
        staticClass:
          "absolute bottom-0 left-0 w-full h-2 bg-gray-300 rounded outline-none appearance-none",
        attrs: { type: "range", min: _vm.min, max: _vm.max, step: "1" },
        domProps: { value: _vm.sliderMin },
        on: {
          change: _vm.changeHandler,
          __r: function ($event) {
            _vm.sliderMin = $event.target.value
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.sliderMax,
            expression: "sliderMax",
          },
        ],
        staticClass:
          "absolute bottom-0 left-0 w-full h-2 bg-gray-300 rounded outline-none appearance-none",
        attrs: { type: "range", min: _vm.min, max: _vm.max, step: "1" },
        domProps: { value: _vm.sliderMax },
        on: {
          change: _vm.changeHandler,
          __r: function ($event) {
            _vm.sliderMax = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "w-12 font-semibold text-center pb-2" }, [
      _vm._v(_vm._s(_vm.sliderMax)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }