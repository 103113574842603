
import { NumberRange } from '@/models/NumberRange';
import { PropType } from 'vue';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component<NumberRangeComponent>({})
export default class NumberRangeComponent extends Vue {
  @Prop({ default: 0 })
  readonly min!: number;

  @Prop({ default: 100 })
  readonly max!: number;

  @Prop({
    type: Object as PropType<NumberRange>
  })
  readonly numberRange?: NumberRange;

  minValue = 0;
  maxValue = 0;

  mounted() {
    this.minValue = this.numberRange?.minValue || this.min;
    this.maxValue = this.numberRange?.maxValue || this.max;
  }

  changeHandler() {
    return this.emitValueChange();
  }

  @Emit('emitValueChange')
  emitValueChange(): NumberRange {
    return {
      minValue: this.minValue,
      maxValue: this.maxValue
    } as NumberRange;
  }

  get sliderMin() {
    return this.minValue.toString();
  }

  set sliderMin(val: string) {
    const parsed: number = parseInt(val);
    if (parsed > this.maxValue) {
      this.maxValue = parsed;
    }
    this.minValue = parsed;
  }

  get sliderMax() {
    return this.maxValue.toString();
  }

  set sliderMax(val: string) {
    const parsed: number = parseInt(val);
    if (parsed < this.minValue) {
      this.minValue = parsed;
    }
    this.maxValue = parsed;
  }
}
