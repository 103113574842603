export type TableOptionValue = number;

export type TableOption = {
  label: string;
  value: TableOptionValue;
  default?: boolean;
};

export type DropDownOptions = TableOption;

// Default or test values
export const paginationItemsPerPage: DropDownOptions[] = [
  { label: '10 per page', value: 10, default: true },
  { label: '20 per page', value: 20 },
  { label: '50 per page', value: 50 }
];

export const miniTablesRowCount = 8;
