/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableRow } from '@/models/Table/Table';

/**
 * Converts a object array to a CSV file and triggers the file download
 * @function
 * @param {object[]} jsonData - Source Object array.
 * @param {string} csvFileName - File name for the CSV file.
 */
export const downloadCSV = (jsonData: Array<TableRow>, csvFileName: string) => {
  const replacer = (_key: unknown, value: unknown) =>
    value === null ? '' : value; // specify how you want to handle null values here
  const header = Object.keys(jsonData[0]);
  const csv = [
    header.join(','), // header row first
    ...jsonData.map((row: TableRow) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    )
  ].join('\r\n');

  const link = document.createElement('a');
  link.id = 'lnkDwnldLnk';

  document.body.appendChild(link);

  const blob = new Blob([csv], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(blob);
  const filename = `${csvFileName}.csv`;

  const downloadEl = document.getElementById('lnkDwnldLnk');
  downloadEl?.setAttribute('download', filename);
  downloadEl?.setAttribute('href', csvUrl);

  downloadEl?.click();
  document.body.removeChild(link);
};

/**
 * Selects and copies an element into the clipboard.
 * @function
 * @param {any} el - HTML element that should be copied.
 */
export const selectElementContents = (el: any) => {
  const body = document.body as any;
  let range, sel;

  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    sel?.removeAllRanges();
    try {
      range.selectNodeContents(el);
      sel?.addRange(range);
    } catch (e) {
      range.selectNode(el);
      sel?.addRange(range);
    }
  } else if (body.createTextRange) {
    range = body.createTextRange();
    range.moveToElementText(el);
    range.select();
  }
  document.execCommand('copy');
  sel?.removeAllRanges();
};
